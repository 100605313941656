import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarFilters from '@atlassian/jira-navigation-apps-sidebar-filters/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { LegacyPage } from '@atlassian/jira-router-routes-common/src/ui/legacy-page/main.tsx';
import { globalIssueNavigatorRedirectRoute } from '@atlassian/jira-router-routes-legacy-global-issue-navigator-routes/src/globalIssueNavigatorRedirectRoute.tsx';

export const globalIssueNavigatorRedirectRouteEntry = createEntry(
	globalIssueNavigatorRedirectRoute,
	() => ({
		canTransitionIn: () => false,
		component: LegacyPage,
		layout: globalLayout,
		navigation: {
			menuId: MENU_ID.FILTERS,
			sidebar: AsyncSidebarFilters,
		},
		resources: [...getNavigationResources(), navigationSidebarGlobalResource],
	}),
);
