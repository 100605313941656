import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProjects from '@atlassian/jira-navigation-apps-sidebar-global-settings-projects/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { globalSettingsProjectsCategoriesDeleteRoute } from '@atlassian/jira-router-routes-legacy-global-settings-projects-categories-delete-routes/src/globalSettingsProjectsCategoriesDeleteRoute.tsx';

export const globalSettingsProjectsCategoriesDeleteRouteEntry = createEntry(
	globalSettingsProjectsCategoriesDeleteRoute,
	() => ({
		layout: globalSettingsLayout,

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PROJECTS_SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProjects,
		},

		resources: [navigationSidebarGlobalResource],
	}),
);
