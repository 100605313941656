import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { legacyPersonalSettingsAccessTokensRoute } from '@atlassian/jira-router-routes-legacy-personal-settings-access-tokens-routes/src/legacyPersonalSettingsAccessTokensRoute.tsx';

export const legacyPersonalSettingsAccessTokensRouteEntry = createEntry(
	legacyPersonalSettingsAccessTokensRoute,
	() => ({
		layout: globalLayout,
	}),
);
