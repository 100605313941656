// like !default / !edit / %21viewLogs
import { ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_TIME_TRACKING } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';

const defaultSuffix = '([!%]?[\\w]*)';

export const globalSettingsIssueTimeTrackingRoute: InvariantRoute = {
	name: ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_TIME_TRACKING,
	path: `/secure/admin/TimeTrackingAdmin${defaultSuffix}.jspa`,
};
