import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { legacyDashboardsConfigureRoute } from '@atlassian/jira-router-routes-legacy-dashboards-configure-routes/src/legacyDashboardsConfigureRoute.tsx';

export const legacyDashboardsConfigureRouteEntry = createEntry(
	legacyDashboardsConfigureRoute,
	() => ({
		layout: globalLayout,

		navigation: {
			menuId: MENU_ID.DASHBOARDS,
		},
	}),
);
