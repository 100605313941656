// like !default / !edit / %21viewLogs
import { ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_PERMISSIONS_SCHEMES } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';

const defaultSuffix = '([!%]?[\\w]*)';

export const globalSettingsIssuesPermissionsSchemesRoute: InvariantRoute = {
	name: ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_PERMISSIONS_SCHEMES,
	path: `/secure/admin/ViewPermissionSchemes${defaultSuffix}.jspa`,
};
