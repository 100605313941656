import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { legacyPortfolioTeamRoute } from '@atlassian/jira-router-routes-legacy-portfolio-team-routes/src/legacyPortfolioTeamRoute.tsx';

export const legacyPortfolioTeamRouteEntry = createEntry(legacyPortfolioTeamRoute, () => ({
	layout: globalLayout,

	navigation: {
		menuId: MENU_ID.PLANS,
	},
}));
