// like !default / !edit / %21viewLogs
import { ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_NOTIFICATION_VIEW } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';

const defaultSuffix = '([!%]?[\\w]*)';

export const globalSettingsIssuesNotificationViewRoute: InvariantRoute = {
	name: ROUTE_NAMES_GLOBAL_SETTINGS_ISSUES_NOTIFICATION_VIEW,
	path: `/secure/admin/ViewNotificationSchemes${defaultSuffix}.jspa`,
};
