import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const OpenHelpPanelLinkThroughEventListener = lazy(
	() =>
		import(
			/* webpackChunkName: "async-open-help-panel-link-through-event-listener" */ './services/open-help-panel-link-through-event-listener/index'
		),
	{ ssr: false },
);

export const AsyncOpenHelpPanelLinkThroughEventListener = () => (
	<JSErrorBoundary
		id="open-help-panel-link-through-event-listener"
		packageName="jira-help-panel-button"
	>
		<Placeholder name="async-open-help-panel-link-through-event-listener" fallback={null}>
			<OpenHelpPanelLinkThroughEventListener />
		</Placeholder>
	</JSErrorBoundary>
);

export default AsyncOpenHelpPanelLinkThroughEventListener;
